var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "custom-select",
    attrs: {
      "tabindex": _vm.tabindex
    },
    on: {
      "blur": function ($event) {
        return _vm.handleBlur($event);
      }
    }
  }, [_c('div', {
    staticClass: "selected cursor-pointer block p-3 text-neutral-700",
    class: {
      open: _vm.open
    },
    on: {
      "click": function ($event) {
        _vm.open = !_vm.open;
      }
    }
  }, [_vm.placeholderText ? _c('span', {
    staticClass: "placeholder"
  }, [_vm._v(_vm._s(_vm.placeholderText))]) : [_vm.optionLabel ? _c('span', [_vm._v(_vm._s(_vm.selected[_vm.optionLabel]))]) : _c('span', [_vm._v(_vm._s(_vm.selected))])], _c('span', {
    staticClass: "drop-arrow absolute right-3 top-1/2 transform -translate-y-1/2 leading-0",
    class: {
      expanded: _vm.open
    }
  }, [_c('Chevron', {
    attrs: {
      "direction": "down",
      "width": "14",
      "height": "14"
    }
  })], 1)], 2), _c('div', {
    staticClass: "content",
    class: {
      selectHide: !_vm.open
    }
  }, [_vm.enableSearch ? _c('div', {
    staticClass: "p-4 bg-neutral-200 shadow-border-b flex gap-2 items-center"
  }, [_c('span', {
    staticClass: "flex-shrink-0"
  }, [_c('Search', {
    attrs: {
      "width": "16",
      "height": "16"
    }
  })], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.searchKeyword,
      expression: "searchKeyword"
    }],
    staticClass: "w-full bg-transparent outline-none border-none text-sm",
    attrs: {
      "type": "text",
      "placeholder": "Cari"
    },
    domProps: {
      "value": _vm.searchKeyword
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.search();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.searchKeyword = $event.target.value;
      }
    }
  })]) : _vm._e(), _vm.options && _vm.options.length > 0 ? _vm._l(_vm.options, function (option, i) {
    return _c('div', {
      key: i,
      staticClass: "p-3 text-neutral-700 hover:bg-neutral-200 cursor-pointer",
      class: {
        'bg-neutral-200': option === _vm.selected
      },
      on: {
        "click": function ($event) {
          return _vm.selectItem(option);
        }
      }
    }, [_vm.optionLabel ? _c('span', [_vm._v(_vm._s(option[_vm.optionLabel]))]) : _c('span', [_vm._v(_vm._s(option))])]);
  }) : [_c('span', {
    staticClass: "flex justify-center items-center py-2 text-neutral-500 text-sm"
  }, [_vm._v("No data is available")])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }